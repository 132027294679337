<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="purchasingtou">
        <ul class="purchasingtou_tit">
            <li v-for="i in liebiao" :key='i.path' :class="path==i.path?'bei':''" @click="dianji_jinru(i)">
                {{i.name}}
            </li>
        </ul>
        <p class="purchasingtou_title">
            <span @click="dianji_jinru_caigou_guanli">采购管理 ></span>{{dangqian_biaoti}}
        </p>
        <div class="purchasingtou_box">
            <router-view />
        </div>
    </div>
</template>

<script>
// import {} from '../../api/api'
export default {
  name: 'purchasingtou',
  data () {
    return {
      liebiao: [
        {
          name: '供应商管理',
          path: '/sqe'
        },
        {
          name: '供应商价格',
          path: '/supplierprice'
        },
        {
          name: '供应商比价',
          path: '/price'
        },
        {
          name: '采购规则',
          path: '/procurement_rules'
        }
      ],
      path: '',
      dangqian_biaoti: ''
    }
  },
  created () {
    this.jianting(this.$route.path)
    this.path = this.$route.path
  },
  mounted () {
        
  },
  watch: {
    $route () {
      let date = ''
      date = this.$route.fullPath
      this.path = date
      console.log(date)
      this.jianting(date)
    }
  },
  methods: {
    jianting (path) {
      this.liebiao.forEach(item => {
        if (item.path == path) {
          this.dangqian_biaoti = item.name
        }
      })
    },
    dianji_jinru (i) {
      this.$router.push(i.path)
      this.dangqian_biaoti = i.name
    },
    dianji_jinru_caigou_guanli () {
      this.$router.push('/purchasing')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './chanpin.scss';
</style>

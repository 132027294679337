import { render, staticRenderFns } from "./Purchasingtou.vue?vue&type=template&id=140a2128&scoped=true&"
import script from "./Purchasingtou.vue?vue&type=script&lang=js&"
export * from "./Purchasingtou.vue?vue&type=script&lang=js&"
import style0 from "./Purchasingtou.vue?vue&type=style&index=0&id=140a2128&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "140a2128",
  null
  
)

export default component.exports